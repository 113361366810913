$(document).ready(function(){

    /* ekko-lightbox */
    $(document).delegate('*[data-toggle="lightbox"]', 'click', function(event) {
        event.preventDefault();
        $(this).ekkoLightbox({
            always_show_close:false,
            right_arrow_class:  ".icon-next",
            left_arrow_class:   ".icon-prev",
        });
    });
    /* end ekko-lightbox */

    /* tinymce */
    tinymce.init({
        selector: "textarea.tinymce",
        plugins: "link autolink code",
        toolbar: "undo redo | bold italic | cut copy paste | bullist | link | undo redo | removeformat | code",
        statusbar: false,
        menubar : false
     });
    /* end tinymce */

    /* order table entries */
    $('table.table-order tbody').sortable({
            handle: 'td',
            helper: 'clone',
            start: function(event, ui) {
            },
            update: function() {
                order = new Array();
                $('tr', this).each(function(){
                    order.push( $(this).find('input[name="action_to[]"]').val() );
                });
                order = order.join(',');

                $.post($("table").data("action"), { order: order, _token : $("table").data('token') }, function() {
                });
            },
            stop: function(event, ui) {
            }

        }).disableSelection();
        /* end order table entries */


        /* slugify */
        $('.slug-target').slugify('.slug-source');
        /* end slugify */
});

/* bootbox */
$(document).on("click", ".confirm-delete", function(e) {
    e.preventDefault();
    var $link = $(this);
    bootbox.dialog({
        message: "Are you sure you want to delete?",
        buttons: {
            success: {
                label: "Cancel",
                className: "btn-primary",
                callback: function() {
                }
        },
            danger: {
                label: "Delete",
                className: "btn-danger",
                callback: function() {
                    document.location.assign($link.attr('href'));
                }
            }
        }
    });
});
/* end bootbox */